import { ReactNode } from "react";

import { cn } from "@libs/utils";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@components/ui/dialog";

type PopupProps = {
  children: ReactNode;
  onClose: () => void;
  title?: string;
  className?: string;
};

const Popup = ({ onClose, children, title, className }: PopupProps) => {
  return (
    <Dialog modal open onOpenChange={onClose}>
      <DialogContent
        role="dialog"
        className={cn("flex flex-col gap-5 max-w-[1000px] overflow-auto", className)}
      >
        {title && (
          <DialogHeader>
            <DialogTitle className="text-xl font-medium">{title}</DialogTitle>
          </DialogHeader>
        )}

        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
