import { Control, Controller } from "react-hook-form";

import {
  CheckboxField,
  ChipField,
  PhoneField,
  RadioField,
  SelectField,
  SwitchField,
  TextField,
  TextareaField,
} from "./Fields";

export type FieldConfig = {
  id?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  type?:
    | "checkbox"
    | "date"
    | "dropdown"
    | "email"
    | "input"
    | "radio"
    | "phone"
    | "chip"
    | "textarea"
    | "switch"
    | "time";
  options?: string[] | { label: string; value: string | number | boolean }[];
  [key: string]: any;
};

const FIELDS = {
  input: TextField,
  date: TextField,
  time: TextField,
  email: TextField,
  phone: PhoneField,
  radio: RadioField,
  dropdown: SelectField,
  checkbox: CheckboxField,
  chip: ChipField,
  textarea: TextareaField,
  switch: SwitchField,
};

type FormFieldControlledProps = {
  question: FieldConfig;
  control: Control<any>;
};

export const FormFieldControlled = ({ question, control }: FormFieldControlledProps) => {
  const Field = FIELDS[(question.type as keyof typeof FIELDS) ?? "input"];
  const id = question.id ?? question.name;

  return (
    <Controller
      control={control}
      name={question.name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className="flex flex-col gap-2 z-[1500]">
            {question.label && (
              <label className="text-[#3C3C4399] text-sm" htmlFor={question.name}>
                {question.label}
              </label>
            )}
            <Field {...question} {...field} id={id} />

            {Boolean(error) && <span className="text-sm text-red-500">{error?.message}</span>}
          </div>
        );
      }}
    />
  );
};
