import { ChangeEvent, FocusEvent, forwardRef, memo, useCallback } from "react";

import { Badge } from "@/components/ui/badge";
import { cn } from "@libs/utils";
import metadata from "libphonenumber-js/min/metadata";
import ReactPhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { AutosizeTextarea } from "@components/ui/autosize-textarea";
import { Checkbox } from "@components/ui/checkbox";
import { Input } from "@components/ui/input";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@components/ui/select";
import { Switch } from "@components/ui/switch";

import { FieldConfig } from "./Question";

type FieldProps = FieldConfig & {
  onChange: (event: ChangeEvent<HTMLElement> | string | string[]) => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
  value: any;
};

const MemoizedBadge = memo(Badge);

export const TextField = forwardRef<HTMLInputElement, FieldProps>(
  ({ name, onChange, onBlur, value, disabled, ...props }, ref) => {
    return (
      <Input
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        {...props}
      />
    );
  }
);

type PhoneFieldProps = Pick<FieldProps, "id" | "value" | "onChange" | "disabled" | "name">;

export const PhoneField = forwardRef(
  ({ value, onChange, disabled, name }: PhoneFieldProps, ref) => {
    const handleChange = (value = "") => {
      onChange(value);
    };

    return (
      <ReactPhoneInput
        defaultCountry="US"
        displayInitialValueAsLocalNumber
        metadata={metadata}
        flagUrl="https://purecatamphetamine.github.io/country-flag-icons/1x1/{XX}.svg"
        className="border p-3 px-5 rounded-md h-[52px] [&_input]:focus-within:outline-none [&_input]:px-2 text-sm"
        value={value}
        disabled={disabled}
        name={name}
        onChange={value => handleChange(value as string)}
      />
    );
  }
);

export const RadioField = forwardRef<HTMLInputElement, Partial<FieldProps>>(
  ({ name, options, onChange, onBlur, value, disabled, align = "vertical" }, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <RadioGroup
        onBlur={onBlur}
        disabled={disabled}
        value={value as string}
        onValueChange={onChange}
        className={cn("flex flex-wrap gap-3", {
          "flex-row gap-5": align === "horizontal",
          "flex-col": align === "vertical",
        })}
      >
        {fieldOptions?.map(option => (
          <div className="flex items-center space-x-2" key={`radio_${name}_${option.value}`}>
            <RadioGroupItem value={option.value as any} id={`radio_${name}_${option.value}`} />
            <label htmlFor={`radio_${name}_${option.value}`} className="text-sm text-[#374151]">
              {option.label}
            </label>
          </div>
        ))}
      </RadioGroup>
    );
  }
);

export const CheckboxField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, value = [], onChange, disabled, align = "vertical" }, ref) => {
    const fieldOptions = getOptions(options);

    const handleChange = useCallback(
      (selectedOption: string) => {
        handleMultiSelect(value, selectedOption, onChange);
      },
      [value, onChange]
    );

    const handleClick = useCallback(
      (optionValue: string) => () => {
        handleChange(optionValue);
      },
      [handleChange]
    );

    return (
      <div
        className={cn("flex flex-wrap gap-3", {
          "flex-row gap-5": align === "horizontal",
          "flex-col": align === "vertical",
        })}
      >
        {fieldOptions?.map(option => (
          <div className="flex items-center space-x-2" key={`checkbox_${name}_${option.value}`}>
            <Checkbox
              name={name}
              disabled={disabled}
              checked={value.includes(option.value)}
              id={`checkbox_${name}_${option.value}`}
              onCheckedChange={handleClick(option.value as string)}
            />
            <label htmlFor={`checkbox_${name}_${option.value}`} className="text-sm text-[#374151]">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    );
  }
);

export const ChipField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, value = [], onChange }, ref) => {
    const fieldOptions = getOptions(options);

    const handleChange = useCallback(
      (selectedOption: string) => {
        handleMultiSelect(value, selectedOption, onChange);
      },
      [value, onChange]
    );

    const handleClick = useCallback(
      (optionValue: string) => () => {
        handleChange(optionValue);
      },
      [handleChange]
    );

    return (
      <div className="flex flex-col md:flex-row flex-wrap gap-3 mt-2">
        {fieldOptions?.map(option => (
          <MemoizedBadge
            size="lg"
            asChild
            key={`chip_${name}_${option.value}`}
            variant={value.includes(option.value) ? "info-light" : "outline"}
            className="focus:ring-0 focus:ring-offset-0 text-sm font-normal text-left"
          >
            <button onClick={handleClick(option.value as string)}>{option.label}</button>
          </MemoizedBadge>
        ))}
      </div>
    );
  }
);

export const SelectField = forwardRef<HTMLDivElement, Partial<FieldProps>>(
  ({ name, options, onChange, value, placeholder = "Select an option", disabled }, ref) => {
    const fieldOptions = getOptions(options);

    return (
      <Select name={name} onValueChange={onChange} value={value} disabled={disabled}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{placeholder}</SelectLabel>
            {fieldOptions?.map(option => (
              <SelectItem key={`select_${name}_${option.value}`} value={option.value as string}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  }
);

export const TextareaField = forwardRef<HTMLTextAreaElement, Partial<FieldProps>>(
  (question, ref) => {
    return <AutosizeTextarea {...question} />;
  }
);

export const SwitchField = forwardRef<HTMLInputElement, Partial<FieldProps>>(
  ({ name, onChange, value, disabled, ...props }, ref) => {
    return (
      <Switch
        name={name}
        checked={value}
        onCheckedChange={onChange}
        disabled={disabled}
        {...props}
      />
    );
  }
);

// ==============================================
function getOptions(options: FieldConfig["options"]) {
  return options?.map(option => {
    const isObject = typeof option === "object";

    return {
      value: isObject ? option.value : option,
      label: isObject ? option.label : option,
    };
  });
}

const handleMultiSelect = (
  value: string[],
  selectedOption: string,
  onChange: (value: string[]) => void
) => {
  if (value.includes(selectedOption)) {
    return onChange(value.filter(item => item !== selectedOption));
  }

  onChange([...value, selectedOption]);
};
